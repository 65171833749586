import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import Word from "../components/RevealingWord"
const NotFoundPage = () => (
  <Layout>
    <div className="thankYou">
      <h2 className="pageHeadings ">
        <Word word={`Looks like you are lost`} />
      </h2>
      <h3 className="pageHeadings mb-48">
        <Word word={`404: Page Not found`} />
      </h3>
      <Link className="primaryCta " to="/">
        Back to Home
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
